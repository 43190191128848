.section1 {
  height: calc(100vh + 2rem);
}

.section1 .swiper {
  height: 100%;
}

.section1 .swiper .swiper-slide {
  overflow: hidden;
}

.section1 .swiper .swiper-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.section1 .swiper .swiper-slide b {
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.section1 .swiper .swiper-slide .banner-t {
  position: absolute;
  top: 0%;
  color: #fff;
  width: 100%;
  text-align: right;
  z-index: 2;
  height: 100vh;
  display: flex;
  align-items: center;
  transform: translateX(-10%);
  opacity: 0;
  transition: all .8s;
  transition-delay: .6s;
}

.section1 .swiper .swiper-slide .banner-t h2 {
  width: 100%;
  box-sizing: border-box;
  padding-left: 40%;
  padding-right: 1.2rem;
  font-size: 0.71rem;
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-slide .banner-t h2 {
    padding-left: 35%;
  }
}

@media (max-width: 991px) {
  .section1 .swiper .swiper-slide .banner-t h2 {
    padding-left: 27%;
  }
}

@media (max-width: 767px) {
  .section1 .swiper .swiper-slide .banner-t h2 {
    padding-left: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-slide .banner-t h2 {
    padding-right: 1rem;
  }
}

@media (max-width: 767px) {
  .section1 .swiper .swiper-slide .banner-t h2 {
    padding-right: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-slide .banner-t h2 {
    font-size: 0.8rem;
  }
}

@media (max-width: 991px) {
  .section1 .swiper .swiper-slide .banner-t h2 {
    font-size: 0.9rem;
  }
}

@media (max-width: 767px) {
  .section1 .swiper .swiper-slide .banner-t h2 {
    font-size: 0.5rem;
  }
}

.section1 .swiper .swiper-slide.swiper-slide-active .banner-t {
  transform: translateX(0%);
  opacity: 1;
}

.section1 .swiper .swiper-pagination {
  bottom: auto;
  text-align: right;
  padding-right: 1.2rem;
  top: calc(100vh - .8rem);
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination {
    padding-right: 1rem;
  }
}

@media (max-width: 767px) {
  .section1 .swiper .swiper-pagination {
    padding-right: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination {
    top: calc(100vh - 50px);
  }
}

.section1 .swiper .swiper-pagination .swiper-pagination-bullet {
  background-color: #ebf7fe;
  opacity: 1;
  transition: all .3s;
  flex-shrink: 0;
  border-radius: 10px;
  margin: 0 0.03rem;
  width: 0.12rem;
  height: 0.12rem;
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet {
    margin: 0 3px;
  }
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
  }
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet {
    height: 12px;
  }
}

.section1 .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #008dc5;
  width: 0.3rem;
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 30px;
  }
}

.section2 {
  position: relative;
  z-index: 2;
  pointer-events: none;
  transform: translateY(-3.6rem);
  margin-bottom: -3.6rem;
}

@media (max-width: 1024px) {
  .section2 {
    transform: translateY(-25vw);
  }
}

@media (max-width: 991px) {
  .section2 {
    transform: translateY(-12vw);
  }
}

@media (max-width: 767px) {
  .section2 {
    transform: translateY(-25vw);
  }
}

@media (max-width: 1024px) {
  .section2 {
    margin-bottom: -25vw;
  }
}

@media (max-width: 991px) {
  .section2 {
    margin-bottom: -12vw;
  }
}

@media (max-width: 767px) {
  .section2 {
    margin-bottom: -25vw;
  }
}

.section2 .sec2-text {
  position: relative;
  box-sizing: border-box;
  z-index: 1;
  opacity: 0;
  transform: translate3d(-5%, 3%, 0);
  transition: transform 3000ms cubic-bezier(0.19, 1, 0.22, 1), opacity 0.4s linear;
  pointer-events: all;
  padding-left: 1.2rem;
  padding-right: 1.5rem;
  padding-top: 1.3rem;
  width: 57%;
}

@media (max-width: 1024px) {
  .section2 .sec2-text {
    padding-left: 1rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-text {
    padding-left: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-text {
    padding-right: 2rem;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-text {
    padding-right: 1rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-text {
    padding-right: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-text {
    width: 68%;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-text {
    width: 100%;
  }
}

.section2 .sec2-text .sec2-text-bg {
  content: '';
  display: block;
  width: 200%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  border-top-right-radius: 1.3rem;
  transform: skew(28deg);
  pointer-events: none;
  background-image: linear-gradient(to top, #ebf7fe 0%, #008dc5 50%, #008dc5 100%);
  right: 0;
}

@media (max-width: 991px) {
  .section2 .sec2-text .sec2-text-bg {
    right: -11%;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-text .sec2-text-bg {
    right: -36%;
  }
}

.section2 .sec2-text .t {
  color: #fff;
  margin-bottom: 0.65rem;
}

.section2 .sec2-text .t .common-title {
  margin-bottom: 0.6rem;
}

.section2 .sec2-text .t .desc {
  line-height: 1.88;
}

.section2 .sec2-text .link {
  position: relative;
  z-index: 1;
  opacity: 0;
  transform: translate3d(-10%, 10%, 0);
  transition: transform 3000ms cubic-bezier(0.19, 1, 0.22, 1), opacity 0.4s linear;
  width: 70%;
  padding-top: 0.35rem;
  padding-bottom: 2rem;
}

@media (max-width: 767px) {
  .section2 .sec2-text .link {
    width: 80%;
  }
}

.section2 .sec2-text .link::after {
  content: '';
  display: block;
  width: 200%;
  height: 100%;
  opacity: .6;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  border-top-right-radius: 1rem;
  transform: skew(28deg);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
}

.section2 .sec2-text .link.is-inview {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.section2 .sec2-img {
  position: absolute;
  bottom: 0;
  z-index: -1;
  background-color: #ebf7fe;
  border-top-right-radius: 1.3rem;
  overflow: hidden;
  pointer-events: all;
  opacity: 0;
  transform: skew(28deg) translate3d(-5%, 3%, 0);
  transition: transform 3000ms cubic-bezier(0.19, 1, 0.22, 1), opacity 0.4s linear;
  right: -5%;
  width: 55%;
  height: 80%;
}

@media (max-width: 991px) {
  .section2 .sec2-img {
    display: none;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-img {
    right: -7%;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-img {
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-img {
    height: 70%;
  }
}

.section2 .sec2-img b {
  display: block;
  width: 100%;
  height: 100%;
  background-position: bottom right;
  transform: skew(-28deg) translateX(5%);
}

.section2.is-inview .sec2-text {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.section2.is-inview .sec2-img {
  transition-delay: .3s;
  opacity: 1;
  transform: skew(28deg) translate3d(0, 0, 0);
}

.section3 {
  position: relative;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section3 {
    margin-bottom: 0.8rem;
  }
}

.section3 .sec3-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: translateY(5vw);
  transition: transform 3000ms cubic-bezier(0.19, 1, 0.22, 1), opacity 0.4s linear;
  height: 100vh;
  position: absolute;
}

@media (max-width: 1024px) {
  .section3 .sec3-cont {
    height: auto;
  }
}

@media (max-width: 1024px) {
  .section3 .sec3-cont {
    position: static;
  }
}

.section3 .sec3-cont.fixed {
  position: fixed;
}

@media (max-width: 1024px) {
  .section3 .sec3-cont.fixed {
    position: static;
  }
}

.section3 .sec3-cont.abs {
  top: auto;
  bottom: 0;
  position: absolute;
}

@media (max-width: 1024px) {
  .section3 .sec3-cont.abs {
    position: static;
  }
}

.section3 .sec3-cont .cont {
  background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0.2));
  border-radius: .5rem 1.5rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0.9rem;
}

@media (max-width: 767px) {
  .section3 .sec3-cont .cont {
    padding-left: 0.3rem;
  }
}

@media (max-width: 767px) {
  .section3 .sec3-cont .cont {
    padding-right: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .section3 .sec3-cont .cont {
    padding-top: 0.8rem;
  }
}

@media (max-width: 1024px) {
  .section3 .sec3-cont .cont {
    padding-bottom: 0.8rem;
  }
}

@media (max-width: 767px) {
  .section3 .sec3-cont .cont {
    padding-bottom: 0.4rem;
  }
}

@media (max-width: 767px) {
  .section3 .sec3-cont .cont {
    margin-top: 0;
  }
}

.section3 .sec3-cont .cont .img-swiper {
  overflow: hidden;
  border-radius: .2rem .8rem;
  flex-shrink: 0;
  opacity: 0;
  transition: transform 3000ms cubic-bezier(0.19, 1, 0.22, 1), opacity 0.4s linear;
  width: 26.3vw;
  height: 31.51vw;
  transform: translateY(0);
}

@media (max-width: 1024px) {
  .section3 .sec3-cont .cont .img-swiper {
    display: none;
  }
}

.section3 .sec3-cont .cont .img-swiper .swiper {
  height: 100%;
}

.section3 .sec3-cont .cont .img-swiper .swiper .swiper-slide b {
  display: block;
  width: 100%;
  height: 100%;
}

.section3 .sec3-cont .cont .text-swiper {
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: right;
  padding-right: 0.55rem;
  width: 49.5vw;
}

@media (max-width: 767px) {
  .section3 .sec3-cont .cont .text-swiper {
    text-align: left;
  }
}

@media (max-width: 1024px) {
  .section3 .sec3-cont .cont .text-swiper {
    padding-right: 0;
  }
}

@media (max-width: 1024px) {
  .section3 .sec3-cont .cont .text-swiper {
    width: 100%;
  }
}

.section3 .sec3-cont .cont .text-swiper .common-title {
  text-align: right;
}

@media (max-width: 767px) {
  .section3 .sec3-cont .cont .text-swiper .common-title {
    text-align: center;
  }
}

.section3 .sec3-cont .cont .text-swiper .swiper .swiper-wrapper {
  display: flex;
}

@media (max-width: 1024px) {
  .section3 .sec3-cont .cont .text-swiper .swiper .swiper-wrapper {
    display: block;
  }
}

.section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide {
  align-items: center;
  justify-content: space-between;
  display: block;
}

@media (max-width: 1024px) {
  .section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide {
    display: flex;
  }
}

@media (max-width: 767px) {
  .section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide {
    display: block;
  }
}

.section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide:nth-child(odd) {
  flex-direction: row-reverse;
}

.section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide:not(:last-child) {
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide:not(:last-child) {
    margin-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide:not(:last-child) {
    margin-bottom: 0.6rem;
  }
}

.section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide .t {
  width: 100%;
}

@media (max-width: 1024px) {
  .section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide .t {
    width: 49.5vw;
  }
}

@media (max-width: 767px) {
  .section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide .t {
    width: 100%;
  }
}

.section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide .t .title {
  color: #008dc5;
  font-size: 0.36rem;
  margin-bottom: 0.2rem;
}

@media (max-width: 1024px) {
  .section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide .t .title {
    font-size: 0.46rem;
  }
}

@media (max-width: 991px) {
  .section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide .t .title {
    font-size: 0.52rem;
  }
}

@media (max-width: 767px) {
  .section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide .t .title {
    font-size: 0.36rem;
  }
}

.section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide .t .desc {
  line-height: 1.7;
}

.section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide .mob-img {
  border-top-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
  border-top-right-radius: .8rem;
  border-bottom-left-radius: .8rem;
  overflow: hidden;
  width: 0;
  display: none;
  margin-top: 0;
}

@media (max-width: 1024px) {
  .section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide .mob-img {
    width: 26.3vw;
  }
}

@media (max-width: 767px) {
  .section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide .mob-img {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide .mob-img {
    display: block;
  }
}

@media (max-width: 767px) {
  .section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide .mob-img {
    margin-top: 0.3rem;
  }
}

.section3 .sec3-cont .cont .text-swiper .swiper .swiper-slide .mob-img img {
  display: block;
  width: 100%;
  height: auto;
}

.section3 .sec3-cont .cont .text-swiper .swiper-pagination {
  right: 0;
  left: auto;
}

@media (max-width: 1024px) {
  .section3 .sec3-cont .cont .text-swiper .swiper-pagination {
    display: none;
  }
}

.section3 .sec3-cont .cont .text-swiper .swiper-pagination.swiper-pagination-progressbar {
  background: #d2ebf5;
  border-radius: 3px;
}

.section3 .sec3-cont .cont .text-swiper .swiper-pagination.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #33b5e8;
  border-radius: 3px;
}

.section3 .sec3-zw div {
  height: 50vh;
}

@media (max-width: 1024px) {
  .section3 .sec3-zw div {
    height: 0;
  }
}

.section3 .sec3-zw div:last-child {
  height: 100vh;
}

@media (max-width: 1024px) {
  .section3 .sec3-zw div:last-child {
    height: 0;
  }
}

.section3.is-inview .sec3-cont {
  opacity: 1;
  transform: translateY(0);
}

.section3.is-inview .sec3-cont .cont .img-swiper {
  transition-delay: .3s;
  opacity: 1;
  transform: translateY(-0.9rem);
}

.section4 {
  position: relative;
}

.section4 .sec4-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: translateY(5vw);
  transition: transform 3000ms cubic-bezier(0.19, 1, 0.22, 1), opacity 0.4s linear;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  height: 100vh;
  position: absolute;
}

@media (max-width: 1024px) {
  .section4 .sec4-cont {
    padding-top: 1rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-cont {
    padding-top: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .section4 .sec4-cont {
    padding-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-cont {
    padding-bottom: 0.3rem;
  }
}

.section4 .sec4-cont.fixed {
  position: fixed;
}

.section4 .sec4-cont.abs {
  top: auto;
  bottom: 0;
  position: absolute;
}

.section4 .sec4-cont .cont {
  border-radius: .2rem 1rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.section4 .sec4-cont .cont .img-swiper {
  flex-shrink: 0;
  transform: scale(1.2);
  transition: transform 3000ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 100%;
  height: 100%;
}

.section4 .sec4-cont .cont .img-swiper .swiper {
  height: calc(100vh - 1.2rem);
}

@media (max-width: 1024px) {
  .section4 .sec4-cont .cont .img-swiper .swiper {
    height: calc(100vh - 2rem);
  }
}

@media (max-width: 767px) {
  .section4 .sec4-cont .cont .img-swiper .swiper {
    height: calc(100vh - .6rem);
  }
}

.section4 .sec4-cont .cont .img-swiper .swiper .swiper-slide {
  position: relative;
  overflow: hidden;
}

.section4 .sec4-cont .cont .img-swiper .swiper .swiper-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
}

.section4 .sec4-cont .cont .img-swiper .swiper .swiper-slide .slide-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.section4 .sec4-cont .cont .img-swiper .swiper .swiper-slide .slide-inner b {
  width: 100%;
  height: 100%;
  transform: scale(1.3);
  transition: all 2s;
  transform-origin: top center;
}

.section4 .sec4-cont .cont .img-swiper .swiper .swiper-slide .slide-inner b.pc-img {
  display: block;
}

@media (max-width: 1024px) {
  .section4 .sec4-cont .cont .img-swiper .swiper .swiper-slide .slide-inner b.pc-img {
    display: none;
  }
}

.section4 .sec4-cont .cont .img-swiper .swiper .swiper-slide .slide-inner b.mob-img {
  display: none;
}

@media (max-width: 1024px) {
  .section4 .sec4-cont .cont .img-swiper .swiper .swiper-slide .slide-inner b.mob-img {
    display: block;
  }
}

.section4 .sec4-cont .cont .img-swiper .swiper .swiper-slide.swiper-slide-active .slide-inner b {
  transform: scale(1);
}

.section4 .sec4-cont .cont .sec4-text {
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: #fff;
  width: 100%;
}

.section4 .sec4-cont .cont .sec4-text .desc {
  font-size: 0.26rem;
  margin-top: 0.35rem;
  margin-bottom: 1.2rem;
}

@media (max-width: 1024px) {
  .section4 .sec4-cont .cont .sec4-text .desc {
    font-size: 0.36rem;
  }
}

@media (max-width: 991px) {
  .section4 .sec4-cont .cont .sec4-text .desc {
    font-size: 0.46rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-cont .cont .sec4-text .desc {
    font-size: 0.36rem;
  }
}

.section4 .sec4-cont .cont .sec4-text .tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  line-height: 1;
  font-size: 0.28rem;
}

@media (max-width: 1024px) {
  .section4 .sec4-cont .cont .sec4-text .tab {
    font-size: 0.38rem;
  }
}

@media (max-width: 991px) {
  .section4 .sec4-cont .cont .sec4-text .tab {
    font-size: 0.48rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-cont .cont .sec4-text .tab {
    font-size: 0.38rem;
  }
}

.section4 .sec4-cont .cont .sec4-text .tab a {
  display: block;
  transition: all .3s;
  border-radius: .15rem;
  background: rgba(0, 0, 0, 0.3);
  position: relative;
  padding: 0.12rem 0.2rem 0.08rem 0.2rem;
}

.section4 .sec4-cont .cont .sec4-text .tab a:nth-child(1).active {
  background: #45a0cb;
}

.section4 .sec4-cont .cont .sec4-text .tab a:nth-child(2).active {
  background: #72882f;
}

.section4 .sec4-cont .cont .sec4-text .tab a:nth-child(3).active {
  background: #e594a9;
}

.section4 .sec4-cont .cont .sec4-text .tab a:nth-child(4).active {
  background: #c69a32;
}

.section4 .sec4-zw div {
  height: 50vh;
}

.section4 .sec4-zw div:last-child {
  height: 100vh;
}

.section4.is-inview .sec4-cont {
  transition-delay: .3s;
  opacity: 1;
  transform: translateY(0);
}

.section4.is-inview .sec4-cont .cont .img-swiper {
  transition-delay: .3s;
  transform: scale(1);
}

.section5 {
  margin-top: 1.2rem;
}

@media (max-width: 767px) {
  .section5 {
    margin-top: 0.8rem;
  }
}

.section5 .common-title {
  padding-left: 1.5rem;
  margin-bottom: 0.45rem;
}

@media (max-width: 991px) {
  .section5 .common-title {
    padding-left: 0;
  }
}

.section5 .sec5-desc {
  line-height: 1.53;
  font-size: 0.26rem;
  padding-left: 42.7vw;
  margin-bottom: 2.1rem;
}

@media (max-width: 1024px) {
  .section5 .sec5-desc {
    font-size: 0.36rem;
  }
}

@media (max-width: 991px) {
  .section5 .sec5-desc {
    font-size: 0.46rem;
  }
}

@media (max-width: 767px) {
  .section5 .sec5-desc {
    font-size: 0.36rem;
  }
}

@media (max-width: 991px) {
  .section5 .sec5-desc {
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .section5 .sec5-desc {
    margin-bottom: 2.6rem;
  }
}

@media (max-width: 767px) {
  .section5 .sec5-desc {
    margin-bottom: 0.6rem;
  }
}

.section5 ul {
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
}

@media (max-width: 767px) {
  .section5 ul {
    display: block;
  }
}

.section5 ul li {
  background-color: #008dc5;
  overflow: hidden;
  border-radius: .2rem .5rem;
  padding-bottom: 0.25rem;
  width: 32.5%;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section5 ul li {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section5 ul li {
    margin-bottom: 0.4rem;
  }
}

.section5 ul li .item-img {
  overflow: hidden;
  border-radius: .5rem;
  border-top-left-radius: .2rem;
  height: 17.6vw;
}

@media (max-width: 767px) {
  .section5 ul li .item-img {
    height: 56.6vw;
  }
}

.section5 ul li .item-img b {
  display: block;
  height: 100%;
  transition: all .3s;
}

.section5 ul li .item-text {
  background-color: rgba(235, 247, 254, 0.8);
  border-radius: .1rem .3rem;
  backdrop-filter: blur(5px);
  z-index: 1;
  position: relative;
  margin-top: -1.1rem;
  width: calc(100% - .5rem);
  margin-left: 0.25rem;
  padding: 0.4rem 0.35rem;
}

@media (max-width: 767px) {
  .section5 ul li .item-text {
    margin-top: -1.2rem;
  }
}

.section5 ul li .item-text .title {
  text-wrap: nowrap;
  font-size: 0.3rem;
  margin-bottom: 0.9rem;
}

@media (max-width: 1024px) {
  .section5 ul li .item-text .title {
    font-size: 0.32rem;
  }
}

@media (max-width: 767px) {
  .section5 ul li .item-text .title {
    font-size: 0.4rem;
  }
}

.section5 ul li:nth-child(1) {
  margin-top: -2.7rem;
}

@media (max-width: 991px) {
  .section5 ul li:nth-child(1) {
    margin-top: -1.7rem;
  }
}

@media (max-width: 767px) {
  .section5 ul li:nth-child(1) {
    margin-top: 0;
  }
}

.section5 ul li:nth-child(2) {
  margin-top: -1.35rem;
}

@media (max-width: 991px) {
  .section5 ul li:nth-child(2) {
    margin-top: -0.85rem;
  }
}

@media (max-width: 767px) {
  .section5 ul li:nth-child(2) {
    margin-top: 0;
  }
}

.section5 ul li:hover .item-img b {
  transform: scale(1.05);
}
