﻿//背景用色
$color-main:#008dc5; //主题色
$color-main-dark:#1482cf;
$color-main-light:#0055b7; 
$color-main-bg:#d3eefb;
$color-main-bg-dark:#a4ddf6;

//border用色
$color-line:#f2f2f2;

//文字用色
$color-red:#ff0909; //错误提示文字颜色
$color-title:#000; //标题黑色
$color-text:#075699; //正文颜色  80%黑
$color-desc:#666; //解释性文字颜色  60%黑

$font40:(md:.5rem,sm:.6rem,xs:.4rem);
$font50:(md:.6rem,sm:.7rem,xs:.48rem);
$font60:(md:.7rem,sm:.8rem,xs:.56rem);
$font70:(md:.8rem,sm:.9rem,xs:.6rem);
$font90:(md:.9rem,sm:1rem,xs:.6rem);
$font110:(md:1.3rem,sm:1.5rem,xs:.8rem);

// 动画效果持续时间
$anime-duration: 0.4s;
//贝塞尔曲线
$anime-bezier:cubic-bezier(0.77, 0, 0.175, 1);

//header高度
$header-height-base: .7rem;
$header-height-ratio: (md:.9rem,sm:1rem,xs:.8rem);

// 圆角
$bg-radius: 1.3rem;
$bg-skew: 28deg;
$bg-skew-left: -28deg;

//内容部分左右留白
$container-gutter-base:8.125%;
$container-gutter-ratio: 6 / 8.125;

//阴影
$shadow-offset: 0px 3px;
$shadow-spread: 10px;
$shadow-color:#dbdada;

$jason-map-width: 400px !default;
$jason-map-duration: .5s !default;
$jason-map-theme: $color-main !default;

//汉堡包按钮参数设置
$hamburger-layer-width: 25px; //汉堡包按钮每一条线的宽度
$hamburger-layer-height: 2px; //汉堡包按钮每一条线的高度
$hamburger-layer-spacing: 5px; //汉堡包按钮每一条线的高度间隔
$hamburger-layer-color: #fff; //汉堡包按钮线的颜色
$hamburger-layer-border-radius: 0; //汉堡包按钮线的圆角效果

// 响应式断点，需要从小到大写
$layout-responsive-breakpoint: ( // 手机
  xs: (min-width: 0px, container-width: 100%),
  // 平板
  sm: (min-width: 768px, container-width: 100%),
  // 小屏
  md: (min-width: 992px, container-width: 100%),
  // 中屏
  mmd: (min-width: 1025px, container-width: 100%),
  // 大屏
  lg: (min-width: 1367px, container-width: 100%),

  llg: (min-width: 1601px, container-width: 100%)) !default;
