@import "../../assets/styles/utils";

.section1{
  @include res(height,calc(100vh + 2rem));
  .swiper{
    height: 100%;
    .swiper-slide{
      overflow: hidden;
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.2);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      b{
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
      }
      .banner-t{
        position: absolute;
        top: 0%;
        color: #fff;
        width: 100%;
        text-align: right;
        z-index: 2;
        height: 100vh;
        display: flex;
        align-items: center;
        transform: translateX(-10%);
        opacity: 0;
        transition: all .8s;
        transition-delay: .6s;
        h2{
          width: 100%;
          box-sizing: border-box;
          @include res(padding-left,40%,(md:35%,sm:27%,xs:.3rem));
          @include res(padding-right,1.2rem,(md:1rem,xs:.3rem));
          @include res(font-size,.71rem,(md:.8rem,sm:.9rem,xs:.5rem));
        }
      }
      &.swiper-slide-active{
        .banner-t{
          transform: translateX(0%);
          opacity: 1;
        }
      }
    }
    .swiper-pagination{
      bottom: auto;
      text-align: right;
      @include res(padding-right,1.2rem,(md:1rem,xs:.3rem));
      @include res(top,calc(100vh - .8rem),(md:calc(100vh - 50px)));
      .swiper-pagination-bullet{
        background-color: #ebf7fe;
        opacity: 1;
        transition: all .3s;
        flex-shrink: 0;
        border-radius: 10px;
        @include res(margin,0 .03rem,(md:0 3px));
        @include res(width,.12rem,(md:12px));
        @include res(height,.12rem,(md:12px));
        &.swiper-pagination-bullet-active{
          background-color: $color-main;
          @include res(width,.3rem,(md:30px));
        }
      }
    }
  }
}

.section2{
  position: relative;
  z-index: 2;
  pointer-events: none;
  @include res(transform,translateY(-3.6rem),(md:translateY(-25vw),sm:translateY(-12vw),xs:translateY(-25vw)));
  @include res(margin-bottom,-3.6rem,(md:-25vw,sm:-12vw,xs:-25vw));
  .sec2-text{
    position: relative;
    box-sizing: border-box;
    z-index: 1;
    opacity: 0;
    transform: translate3d(-5%,3%,0);
    transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1), opacity .4s linear;
    pointer-events: all;
    @include res(padding-left,1.2rem,(md:1rem,xs:.3rem));
    @include res(padding-right,1.5rem,(md:2rem,sm:1rem,xs:.3rem));
    @include res(padding-top,1.3rem);
    @include res(width,57%,(md:68%,sm:100%));
    .sec2-text-bg{
      content: '';
      display: block;
      width: 200%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
      border-top-right-radius: $bg-radius;
      transform: skew($bg-skew);
      pointer-events: none;
      background-image: linear-gradient(to top, #ebf7fe 0%,$color-main 50%,$color-main 100%);
      @include res(right,0,(sm:-11%,xs:-36%));
    }
    .t{
      color: #fff;
      @include res(margin-bottom,.65rem);
      .common-title{
        @include res(margin-bottom,.6rem);
      }
      .desc{
        line-height: 1.88;
      }
    }
    .link{
      position: relative;
      z-index: 1;
      opacity: 0;
      transform: translate3d(-10%,10%,0);
      transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1), opacity .4s linear;
      @include res(width,70%,(xs:80%));
      @include res(padding-top,.35rem);
      @include res(padding-bottom,2rem);
      &::after{
        content: '';
        display: block;
        width: 200%;
        height: 100%;
        opacity: .6;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        border-top-right-radius: 1rem;
        transform: skew($bg-skew);
        background-image: linear-gradient(to bottom,rgba(255,255,255,.2),rgba(255,255,255,0));
      }
      &.is-inview{
        opacity: 1;
        transform: translate3d(0,0,0);
      }
    }
  }
  .sec2-img{
    position: absolute;
    bottom: 0;
    z-index: -1;
    background-color: #ebf7fe;
    border-top-right-radius: $bg-radius;
    overflow: hidden;
    pointer-events: all;
    opacity: 0;
    transform: skew($bg-skew) translate3d(-5%,3%,0);
    transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1), opacity .4s linear;
    @include res(display,null,(sm:none));
    @include res(right,-5%,(md:-7%));
    @include res(width,55%,(md:70%));
    @include res(height,80%,(md:70%));
    b{
      display: block;
      width: 100%;
      height: 100%;
      background-position: bottom right;
      transform: skew(-$bg-skew) translateX(5%);
    }
  }
  &.is-inview{
    .sec2-text{
      opacity: 1;
      transform: translate3d(0,0,0);
    }
    .sec2-img{
      transition-delay: .3s;
      opacity: 1;
      transform: skew($bg-skew) translate3d(0,0,0);
    }
  }
}

.section3{
  position: relative;
  @include res(margin-bottom,0,(xs:.8rem));
  .sec3-cont{
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transform: translateY(5vw);
    transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1), opacity .4s linear;
    @include res(height,100vh,(md:auto));
    @include res(position,absolute,(md:static));
    &.fixed{
      @include res(position,fixed,(md:static));
    }
    &.abs{
      top: auto;
      bottom: 0;
      @include res(position,absolute,(md:static));
    }
    .cont{
      background-image: linear-gradient(to right,rgba(255,255,255,1),rgba(255,255,255,.2));
      border-radius: .5rem 1.5rem;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      @include res(display,flex);
      @include res(padding-left,.8rem,(xs:.3rem));
      @include res(padding-right,.8rem,(xs:.3rem));
      @include res(padding-top,0,(md:.8rem));
      @include res(padding-bottom,0,(md:.8rem,xs:.4rem));
      @include res(margin-top,.9rem,(xs:0));
      .img-swiper{
        overflow: hidden;
        border-radius: .2rem .8rem;
        flex-shrink: 0;
        opacity: 0;
        transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1), opacity .4s linear;
        @include res(width,26.3vw);
        @include res(height,31.51vw);
        @include res(transform,translateY(0));
        @include res(display,null,(md:none));
        .swiper{
          height: 100%;
          .swiper-slide{
            b{
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .text-swiper{
        position: relative;
        box-sizing: border-box;
        flex-shrink: 0;
        @include res(text-align,right,(xs:left));
        @include res(padding-right,.55rem,(md:0));
        @include res(width,49.5vw,(md:100%));
        .common-title{
          @include res(text-align,right,(xs:center));
        }
        .swiper{
          .swiper-wrapper{
            @include res(display,flex,(md:block));
          }
          .swiper-slide{
            align-items: center;
            justify-content: space-between;
            @include res(display,block,(md:flex,xs:block));
            &:nth-child(odd){
              flex-direction: row-reverse;
            }
            &:not(:last-child){
              @include res(margin-bottom,0,(md:1rem,xs:.6rem));
            }
            .t{
              @include res(width,100%,(md:49.5vw,xs:100%));
              .title{
                color: $color-main;
                @include res(font-size,.36rem,(md:.46rem,sm:.52rem,xs:.36rem));
                @include res(margin-bottom,.2rem);
              }
              .desc{
                line-height: 1.7;
              }
            }
            .mob-img{
              border-top-left-radius: .2rem;
              border-bottom-right-radius: .2rem;
              border-top-right-radius: .8rem;
              border-bottom-left-radius: .8rem;
              overflow: hidden;
              @include res(width,0,(md:26.3vw,xs:100%));
              @include res(display,none,(md:block));
              @include res(margin-top,0,(xs:.3rem));
              img{
                display: block;
                width: 100%;
                height: auto;
              }
            }
          }
        }
        .swiper-pagination{
          right: 0;
          left: auto;
          @include res(display,null,(md:none));
          &.swiper-pagination-progressbar{
            background: #d2ebf5;
            border-radius: 3px;
            .swiper-pagination-progressbar-fill{
              background: #33b5e8;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }
  .sec3-zw{
    div{
      @include res(height,50vh,(md:0));
      &:last-child{
        @include res(height,100vh,(md:0));
      }
    }
  }
  &.is-inview{
    .sec3-cont{
      opacity: 1;
      transform: translateY(0);
      .cont{
        .img-swiper{
          transition-delay: .3s;
          opacity: 1;
          @include res(transform,translateY(-.9rem));
        }
      }
    }
  }
}


.section4{
  position: relative;
  .sec4-cont{
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transform: translateY(5vw);
    transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1), opacity .4s linear;
    @include res(padding-top,.6rem,(md:1rem,xs:.3rem));
    @include res(padding-bottom,.6rem,(md:1rem,xs:.3rem));
    @include res(height,100vh);
    @include res(position,absolute);
    &.fixed{
      @include res(position,fixed);
    }
    &.abs{
      top: auto;
      bottom: 0;
      @include res(position,absolute);
    }
    .cont{
      border-radius: .2rem 1rem;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      height: 100%;
      overflow: hidden;
      position: relative;
      .img-swiper{
        flex-shrink: 0;
        transform: scale(1.2);
        transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1);
        @include res(width,100%);
        @include res(height,100%);
        .swiper{
          @include res(height,calc(100vh - 1.2rem),(md:calc(100vh - 2rem),xs:calc(100vh - .6rem)));
          .swiper-slide{
            position: relative;
            overflow: hidden;
            &::after{
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              background-color: rgba(0,0,0,.2);
              position: absolute;
              top: 0;
              left: 0;
            }
            .slide-inner{
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              b{
                width: 100%;
                height: 100%;
                transform: scale(1.3);
                transition: all 2s;
                transform-origin: top center;
                &.pc-img{
                  @include res(display,block,(md:none));
                }
                &.mob-img{
                  @include res(display,none,(md:block));
                }
              }
            }
            &.swiper-slide-active{
              .slide-inner b{
                transform: scale(1);
              }
            }
          }
        }
      }
      .sec4-text{
        text-align: center;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        color: #fff;
        width: 100%;
        .desc{
          @include res(font-size,.26rem,(md:.36rem,sm:.46rem,xs:.36rem));
          @include res(margin-top,.35rem);
          @include res(margin-bottom,1.2rem);
        }
        .tab{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          line-height: 1;
          @include res(font-size,.28rem,(md:.38rem,sm:.48rem,xs:.38rem));
          a{
            display: block;
            transition: all .3s;
            border-radius: .15rem;
            background: rgba(0,0,0,.3);
            // backdrop-filter: blur(5px);
            position: relative;
            @include res(padding,.12rem .2rem .08rem .2rem);
            &:nth-child(1){
              &.active{
                background: #45a0cb;
              }
            }
            &:nth-child(2){
              &.active{
                background: #72882f;
              }
            }
            &:nth-child(3){
              &.active{
                background: #e594a9;
              }
            }
            &:nth-child(4){
              &.active{
                background: #c69a32;
              }
            }
          }
        }
      }
    }
  }
  .sec4-zw{
    div{
      @include res(height,50vh);
      &:last-child{
        @include res(height,100vh);
      }
    }
  }
  &.is-inview{
    .sec4-cont{
      transition-delay: .3s;
      opacity: 1;
      @include res(transform,translateY(0));
      .cont{
        .img-swiper{
          transition-delay: .3s;
          transform: scale(1);
        }
      }
    }
  }
}

.section5{
  @include res(margin-top,1.2rem,(xs:.8rem));
  .common-title{
    @include res(padding-left,1.5rem,(sm:0));
    @include res(margin-bottom,.45rem);
  }
  .sec5-desc{
    line-height: 1.53;
    @include res(font-size,.26rem,(md:.36rem,sm:.46rem,xs:.36rem));
    @include res(padding-left,42.7vw,(sm:0));
    @include res(margin-bottom,2.1rem,(sm:2.6rem,xs:.6rem));
  }
  ul{
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    @include res(display,flex,(xs:block));
    li{
      background-color: $color-main;
      overflow: hidden;
      border-radius: .2rem .5rem;
      @include res(padding-bottom,.25rem);
      @include res(width,32.5%,(xs:100%));
      @include res(margin-bottom,0,(xs:.4rem));
      .item-img{
        overflow: hidden;
        border-radius: .5rem;
        border-top-left-radius: .2rem;
        @include res(height,17.6vw,(xs:56.6vw));
        b{
          display: block;
          height: 100%;
          transition: all .3s;
        }
      }
      .item-text{
        background-color: rgba(235,247,254,.8);
        border-radius: .1rem .3rem;
        backdrop-filter: blur(5px);
        z-index: 1;
        position: relative;
        @include res(margin-top,-1.1rem,(xs:-1.2rem));
        @include res(width,calc(100% - .5rem));
        @include res(margin-left,.25rem);
        @include res(padding,.4rem .35rem);
        .title{
          text-wrap: nowrap;
          @include res(font-size,.3rem,(md:.32rem,xs:.4rem));
          @include res(margin-bottom,.9rem);
        }
      }
      &:nth-child(1){
        @include res(margin-top,-2.7rem,(sm:-1.7rem,xs:0));
      }
      &:nth-child(2){
        @include res(margin-top,-1.35rem,(sm:-.85rem,xs:0));
      }
      &:hover{
        .item-img b{
          transform: scale(1.05);
        }
      }
    }
  }
}